/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import AnnualReportCard from "../../components/AnnualReportCard"
import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"
import HomeLogo from "../../components/HomeLogo"

export default class AnnualReportPage extends Component {
  render() {
    const { data } = this.props
    const { edges: annualreports } = data.allMarkdownRemark

    // const { edges: posts } = data.allMarkdownRemark

    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>AnnualReport - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Annual Reports
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-offset-2">
            {annualreports
              .filter(
                (annualreport) =>
                  annualreport.node.frontmatter.templateKey ===
                  "annualreport-page"
              )
              .map(({ node: annualreport }) => (
                <div
                  className="news_item is-size-5"
                  key={annualreport.id}
                  style={{ transform: `none` }}
                >
                  <p>{annualreport.frontmatter.year}</p>
                  <img src={annualreport.frontmatter.cover} />
                  <a href={annualreport.frontmatter.manual_pdf} download>
                    download
                  </a>
                </div>
              ))}
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

AnnualReportPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const annualReportPageQuery = graphql`
  query AnnualReportPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "annualreport-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            cover
            templateKey
            date(formatString: "MMMM DD, YYYY")
            year
            manual_pdf
          }
        }
      }
    }
  }
`
